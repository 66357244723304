<template>
  <v-card class="pa-5" flat>
    <h2>{{ $t('Add new card') }}</h2>

    <v-row>
      <v-col cols sm="6">
        <div class="text-caption mb-2">
          {{ $t('Card details') }}
        </div>
        <v-text-field v-model="billing_details.name" :label="$t('Name')" outlined />
        <div class="card-input-wrapper">
          <div class="card-input">
            <div class="card-input-label">
              {{ $t('Credit or debit card') }}
            </div>
            <div id="card-element" />
          </div>
          <div class="card-input-error">
            <div id="card-errors" class="error--text text-caption" />
          </div>
        </div>
      </v-col>

      <v-col cols sm="6">
        <div class="text-caption mb-2">
          {{ $t('Billing Address') }}
        </div>
        <v-text-field
          v-model="billing_details.address.line1"
          :label="$t('Address line 1')"
          outlined
        />
        <v-text-field
          v-model="billing_details.address.line2"
          :label="$t('Address line 2')"
          outlined
        />
        <v-text-field
          v-model="billing_details.address.city"
          :label="$t('City')"
          outlined
        />
        <v-select
          v-model="billing_details.address.country"
          :items="countriesList"
          :label="$t('Country')"
          :menu-props="{maxHeight: '80vh', ripple: false}"
          outlined
        />
      </v-col>
    </v-row>

    <div class="d-flex align-start justify-end flex-wrap">
      <v-btn
        :ripple="false"
        :to="{name: 'profile-subscription'}"
        class="mb-3"
        color="primary"
        elevation="0"
        outlined
        rounded
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="saving"
        :loading="saving"
        :ripple="false"
        class="ml-4"
        color="primary"
        elevation="0"
        rounded
        @click="saveCard"
      >
        {{ $t('Save card') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProfileAddPaymentMethod',
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      saving: false,
      stripe: null,
      card: null,
      billing_details: {
        name: null,
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          state: null
        }
      }
    }
  },
  computed: {
    ...mapState({
      setupIntent: state => state.subscriptions.setupIntent,
      profile: state => state.profile
    }),
    ...mapGetters({
      countriesList: 'getCountries'
    })
  },
  async mounted () {
    this.billing_details.name = this.profile.name

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

    let elements = this.stripe.elements()
    let style = {
      base: {
        color: '#252525',
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#777777'
        }
      },
      invalid: {
        color: '#f04304',
        iconColor: '#f04304'
      }
    }
    this.card = elements.create('card', {style})
    this.card.mount('#card-element')

    // Handle real-time validation errors from the card Element.
    this.card.on('change', function (event) {
      let displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })
  },
  methods: {
    ...mapMutations({
      setError: 'error/set'
    }),
    ...mapActions({
      getSetupIntent: 'subscriptions/ssSetupIntent'
    }),
    async saveCard () {
      this.saving = true
      try {
        await this.getSetupIntent()

        let {error} = await this.stripe?.confirmCardSetup(
          this.setupIntent.client_secret,
          {
            payment_method: {
              card: this.card,
              billing_details: this.billing_details
            }
          }
        )
        if (error) {
          this.setError({
            mode: 'modal',
            error,
            title: 'Error adding card',
            message: error.message
          })
        } else {
          return this.$router.push({name: 'profile-subscription'})
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-input-wrapper {
    width: 100%;
    padding: 1px 0;

    .card-input {
      position: relative;
      border: thin solid var(--v-outline-base);
      padding: $spacer * 2;
      min-height: 3.45rem;
    }

    .card-input-label {
      color: var(--v-text-lighten1);
      background-color: var(--v-surface-base);
      position: absolute;
      top: -0.85em;
      left: 0.5em;
      padding: 0 $spacer;
      font-size: .75rem;
    }
  }

  #card-element {
    width: 100%;
    max-height: 18px;
    margin: $spacer * 2 0;
  }
</style>
