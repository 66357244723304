<template>
  <v-card class="pa-5 mt-5" flat>
    <h3>
      {{ $t('Payment info') }}
    </h3>

    <div v-if="loading" class="mt-10 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="method in paymentMethods"
        :key="method.id"
        sm="6"
        lg="4"
        xl="3"
        cols
      >
        <v-card class="v-card-inner d-flex flex-column px-5 py-4" flat>
          <v-card-title>
            <div>
              <span class="mr-2">{{ method.brand.toUpperCase() }}</span>
              <span
                v-if="method.default"
                class="subtitle-2 text--disabled text-no-wrap"
              >
                {{ $t('default payment method') }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <div>**** **** **** {{ method.last_four }}</div>
            <div>
              {{ method.exp_month.toString().padStart(2, '0') }}
              /
              {{ method.exp_year % 2000 }}
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="removing === method.id"
              :loading="removing === method.id"
              :ripple="false"
              class="px-5"
              color="primary"
              elevation="0"
              rounded
              outlined
              small
              @click="removeMethod(method.id)"
            >
              {{ $t('Remove card') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-right">
      <v-btn
        :ripple="false"
        :to="{name: 'profile-add-payment-method'}"
        color="primary"
        elevation="0"
        rounded
      >
        {{ $t('Add new card') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'PaymentSavedMethods',
  mixins: [error401handler],
  data () {
    return {
      removing: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      setupIntent: state => state.subscriptions.setupIntent,
      paymentMethods: state => state.subscriptions.paymentMethods,
      profile: state => state.profile
    })
  },
  async mounted () {
    this.loading = true
    try {
      await this.loadPaymentMethods()
    } catch (e) {
      if (e.response?.data?.message !== 'messages.api.user.not_a_stripe_customer') {
        console.log('messages.api.user.not_a_stripe_customer', e.response.data)
      } else {
        this.handleError(e)
      }
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      loadPaymentMethods: 'subscriptions/ssLoadPaymentMethods',
      removePaymentMethod: 'subscriptions/ssRemovePaymentMethod',
      getSetupIntent: 'subscriptions/ssSetupIntent'
    }),
    async removeMethod (methodId) {
      this.removing = methodId
      try {
        await this.removePaymentMethod(methodId)
        await this.loadPaymentMethods()
      } catch (e) {
        if (e.response?.data?.message !== 'messages.api.user.not_a_stripe_customer') {
          console.log('messages.api.user.not_a_stripe_customer', e.response.data)
        } else {
          this.handleError(e)
        }
      } finally {
        this.removing = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
