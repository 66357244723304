<template>
  <v-card class="pa-5" flat>
    <h3>
      {{ $t('Plan details') }}
    </h3>

    <div v-if="loading" class="mt-10 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="plan in plans"
        :key="plan.id"
        class="d-flex align-stretch"
        xl="3"
        lg="4"
      >
        <v-card
          class="v-card-inner d-flex flex-column px-5 py-4 w-100"
          min-height="376"
          min-width="256"
          :dark="plan.id === activePlan.id"
          flat
        >
          <v-card-title>
            <div>
              <span class="text-capitalize">{{ plan.name }}</span>
              <span
                v-if="plan.id === activePlan.id && activePlan.ends_at"
                class="primary--text body-2 ml-2"
              >
                {{ trialDaysLeftMessage }}
              </span>
            </div>
          </v-card-title>
          <v-card-subtitle>
            {{ plan.target_audience }}
          </v-card-subtitle>
          <v-card-text>
            <div class="text-h3 text--primary">
              {{ plan.amount === '' ? $t('Custom') : formatPrice(plan) }}
            </div>
            <div :class="{'transparent--text': plan.amount === ''}">
              {{ $t('per_interval', {interval: plan.interval}) }}
            </div>
            <div v-if="plan.id === activePlan.id && activePlan.ends_at" class="mt-4">
              {{ $t('Trial expires') }}:
              <app-date :value="activePlan.ends_at" />
            </div>
            <div :class="[plan.id === activePlan.id && activePlan.ends_at ? 'mt-6': 'mt-16']">
              <div class="d-flex">
                <v-icon color="primary">
                  {{ icons.check }}
                </v-icon>
                <div class="pl-1">
                  {{ plan.description }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="justify-center">
            <v-btn
              v-if="plan.id === activePlan.id"
              :disabled="unsubscribing || plan.id === null"
              :loading="unsubscribing"
              :ripple="false"
              color="primary"
              elevation="0"
              block
              rounded
              outlined
              @click="unsubscribe"
            >
              {{ $t('Cancel subscription') }}
            </v-btn>
            <v-btn
              v-else
              :disabled="subscribing === plan.id || plan.id === null || (plan.id === null && profile.subscribed && profile.subscribed.id)"
              :loading="subscribing === plan.id"
              :ripple="false"
              color="primary"
              elevation="0"
              block
              rounded
              @click="subscribe(plan.id)"
            >
              {{ profile.subscribed ? $t('Upgrade plan') : $t('Subscribe') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {mdiCheck} from '@mdi/js'
import AppDate from '@/components/AppDate'
import error401handler from '@/mixins/error-401-handler'

const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/

export default {
  name: 'PaymentPlans',
  components: {AppDate},
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      subscribing: false,
      unsubscribing: false,
      icons: {
        check: mdiCheck
      }
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      plans: state => state.subscriptions.plans,
      profile: state => state.profile
    }),
    ...mapGetters({
      defaultPaymentMethodId: 'subscriptions/getDefaultPaymentMethodId',
      activePlan: 'getActivePlan'
    }),
    trialDaysLeftMessage () {
      let trialEndsDateParts = datetimeRegex.exec(this.activePlan.ends_at)
      let trialEnds = new Date(
        trialEndsDateParts[1] * 1,
        trialEndsDateParts[2] * 1 - 1,
        trialEndsDateParts[3] * 1,
        trialEndsDateParts[4] * 1,
        trialEndsDateParts[5] * 1,
        trialEndsDateParts[6] * 1
      )

      let today = new Date()
      let daysLeft = (trialEnds - today) / (1000 * 86400)
      if (daysLeft > 1) {
        return this.$t('daysLeft', {count: Math.floor(daysLeft)})
      } else if (daysLeft > 0) {
        return this.$t('Less than a day left')
      }
      return this.$t('Trial expired')
    }
  },
  async mounted () {
    this.loading = true
    try {
      await this.loadPlans()
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'loadProfile',
      loadPlans: 'subscriptions/ssLoadPlans',
      createSubscription: 'subscriptions/ssCreateSubscription',
      cancelSubscription: 'subscriptions/ssCancelSubscription',
      loadPaymentMethods: 'subscriptions/ssLoadPaymentMethods'
    }),
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show'
    }),
    formatPrice (plan) {
      let dollars = Math.floor(plan.amount / 100)
      let cents = (plan.amount % 100).toString().padStart(2, '0')
      return `${plan.currency_symbol}${dollars}.${cents}`
    },
    async subscribe (planId) {
      this.subscribing = planId

      // First load fresh payment methods, to be sure we've got
      // the most recent default method, because there is a delay
      // in Stripe when assigning new card as "default".
      try {
        await this.loadPaymentMethods()
      } catch (e) {
        if (e.response?.data?.message !== 'messages.api.user.not_a_stripe_customer') {
          console.log('messages.api.user.not_a_stripe_customer', e.response.data)
        } else {
          this.handleError(e)
        }
      }

      if (!this.defaultPaymentMethodId) {
        this.subscribing = false
        this.showNotify({
          color: 'var(--v-primary-lighten1)',
          text: this.$t('PleaseProvideCreditCard'),
          timeout: 10000
        })
        return this.$router.push({name: 'profile-add-payment-method'})
      }

      try {
        await this.createSubscription({
          planId,
          methodId: this.defaultPaymentMethodId
        })
        await this.loadProfile()
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.setError({mode: 'modal', error: e, title: 'Error'})
        }
      } finally {
        this.subscribing = false
      }
    },
    async unsubscribe () {
      this.unsubscribing = true
      try {
        await this.cancelSubscription(this.profile.subscribed.id)
        await this.loadProfile()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.unsubscribing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
