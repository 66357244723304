<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="600"
    persistent
  >
    <template #activator="{on}">
      <div class="mt-2">
        <v-btn
          :ripple="false"
          color="primary"
          outlined
          plain
          rounded
          small
          v-on="on"
        >
          <slot />
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <h4 class="my-5 mx-auto">
          {{ $t('Validate phone number') }}
        </h4>
        <v-btn :ripple="false" icon @click="close">
          <v-icon v-text="icons.close" />
        </v-btn>
      </v-card-title>

      <template v-if="!codeSent">
        <v-card-text>
          <v-form ref="formPhoneNumber">
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="selectedCodeId"
                  :disabled="codeSending"
                  :items="countryCodes"
                  :label="$t('Country code')"
                  :loading="codeSending"
                  :menu-props="{'offset-y': true, ripple: false}"
                  :rules="rules.countryCode"
                  eager
                  item-text="country"
                  item-value="id"
                  outlined
                >
                  <template #item="{item}">
                    {{ item.country }}
                    (+{{ item.dialCode }})
                  </template>
                  <template #selection="{item}">
                    +{{ item.dialCode }}
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="number"
                  :disabled="codeSending"
                  :error-messages="errorMessages"
                  :label="$t('Phone number')"
                  :loading="codeSending"
                  :rules="rules.phoneNumber"
                  outlined
                  type="tel"
                  @keyup.enter="sendCode"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="5">
              <v-btn
                :disabled="codeSending"
                :ripple="false"
                block
                color="primary"
                outlined
                rounded
                @click="close"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                :disabled="codeSending"
                :loading="codeSending"
                :ripple="false"
                block
                color="primary"
                elevation="0"
                rounded
                @click="sendCode"
              >
                {{ $t('Send verification code') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-text>
          <v-form ref="formCode">
            <v-row justify="center">
              <v-col cols="5">
                <v-text-field
                  ref="code"
                  v-model="code"
                  :disabled="codeVerifying"
                  :error-messages="errorMessages"
                  :loading="codeVerifying"
                  :placeholder="$t('Enter 4-digits code')"
                  :rules="rules.code"
                  autocomplete="no"
                  outlined
                  @keyup.enter="checkCode"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-col v-show="resendEnabled" cols="5">
              <v-btn
                :disabled="codeVerifying || codeSending"
                :loading="codeSending"
                :ripple="false"
                block
                color="primary"
                outlined
                rounded
                @click="sendCode"
              >
                {{ $t('Resend verification code') }}
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                :disabled="codeVerifying"
                :loading="codeVerifying"
                :ripple="false"
                block
                color="primary"
                elevation="0"
                rounded
                @click="checkCode"
              >
                {{ $t('Verify code') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {mdiClose} from '@mdi/js'
import error401handler from '@/mixins/error-401-handler'
import countryCodes from '../assets/country-codes'

const ENABLE_RESEND_TIMEOUT = 120 * 1000

export default {
  name: 'ModalPhoneVerify',
  mixins: [error401handler],
  data () {
    return {
      dialog: false,
      countryCodes,
      selectedCodeId: '',
      number: '',
      codeSending: false,
      codeSent: false,
      codeVerifying: false,
      code: '',
      timer: null,
      resendEnabled: false,
      errorMessages: '',
      rules: {
        countryCode: [
          v => !!v || this.$t('Please choose country code')
        ],
        phoneNumber: [
          v => !!v || this.$t('Please enter phone number')
        ],
        code: [
          v => !!v || this.$t('Please enter code')
        ],
      },
      icons: {
        close: mdiClose
      }
    }
  },
  computed: {
    ...mapState({
      country: state => state.profile.locale,
      phone: state => state.profile.phone,
      verified: state => {
        return state.profile.validated_type === 'phone' &&
          !!state.profile.validated_at
      }
    }),
    phoneNumber () {
      let code = this.countryCodes.find(code => code.id === this.selectedCodeId)
      return `+${code?.dialCode}${this.number.replace(/[\D]+/g, '')}`
    }
  },
  mounted () {
    if (this.country) {
      let code = this.countryCodes.find(code => code.iso2code === this.country)
      this.selectedCodeId = code.id
    }
  },
  methods: {
    ...mapMutations({
      setError: 'error/set'
    }),
    ...mapActions([
      'sendVerificationCode',
      'resendVerificationCode',
      'verifyCode',
      'loadProfile'
    ]),
    resetForm () {
      this.selectedCodeId = ''
      this.number = ''
      this.codeSending = false
      this.codeSent = false
      this.codeVerifying = false
      this.code = ''
      this.timer = null
      this.resendEnabled = false
      this.errorMessages = ''
    },
    close () {
      this.dialog = false
      if (this.codeSent) {
        this.resetForm()
      }
    },
    async sendCode () {
      if (!this.$refs.formPhoneNumber.validate()) {
        return
      }

      this.errorMessages = ''
      this.codeSending = true
      try {
        if (this.resendEnabled) {
          await this.resendVerificationCode()
        } else {
          await this.sendVerificationCode(this.phoneNumber)
        }
        this.codeSent = true
        this.resendEnabled = false
        this.timer = setTimeout(() => {
          this.resendEnabled = true
        }, ENABLE_RESEND_TIMEOUT)
        this.$nextTick(() => {
          this.$refs.code.$refs.input.focus()
        })
      } catch (error) {
        if ([422, 503].includes(error.response?.status)) {
          let {message} = error.response.data
          this.errorMessages = message?.phone || message
        } else {
          this.setError({mode: 'modal', error})
        }
      } finally {
        this.codeSending = false
      }
    },
    async checkCode () {
      if (!this.$refs.formCode.validate()) {
        return
      }

      this.errorMessages = ''
      this.codeVerifying = true
      clearTimeout(this.timer)
      try {
        let response = await this.verifyCode(this.code)
        if (response.result) {
          await this.loadProfile()
          this.dialog = false
          this.resetForm()
        }
      } catch (error) {
        if ([422, 503].includes(error.response?.status)) {
          if (typeof error.response.data.message === 'string') {
            this.errorMessages = error.response.data.message
          } else {
            this.errorMessages = Object.values(error.response.data.message)
              .reduce((msg, cur) => {
                msg += Array.isArray(cur) ? cur.join(' ') : cur
                return msg
              }, '')
          }

          this.resendEnabled = false
          this.timer = setTimeout(() => {
            this.resendEnabled = true
          }, ENABLE_RESEND_TIMEOUT)
          this.$nextTick(() => {
            this.$refs.code.$refs.input.focus()
          })
        } else {
          this.setError({mode: 'modal', error})
        }
      } finally {
        this.codeVerifying = false
      }
    },
  }
}
</script>
