<template>
  <span>{{ date }}</span>
</template>

<script>
import {mapState} from 'vuex'

const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/
const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/

export default {
  name: 'AppDate',
  props: {
    value: {type: String, required: true}
  },
  computed: {
    ...mapState({
      locale: state => state.locale
    }),
    date () {
      let source = this.value
      if (dateRegex.test(source)) {
        source += ' 00:00:00'
      }

      let sourceDateParts = datetimeRegex.exec(source)

      let d = new Date(
        sourceDateParts[1] * 1,
        sourceDateParts[2] * 1 - 1,
        sourceDateParts[3] * 1,
        sourceDateParts[4] * 1,
        sourceDateParts[5] * 1,
        sourceDateParts[6] * 1
      )
      return d.toLocaleDateString('ru-RU') // this.locale
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
