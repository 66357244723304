<template>
  <div>
    <payment-plans />
    <payment-saved-methods />
  </div>
</template>

<script>
import PaymentPlans from '@/components/PaymentPlans'
import PaymentSavedMethods from '@/components/PaymentSavedMethods'

export default {
  name: 'ProfileSubscription',
  components: {PaymentSavedMethods, PaymentPlans}
}
</script>

<style lang="scss">
</style>
