<template>
  <v-card class="pa-5" flat>
    <a :href="resetPasswordUrl">
      {{ $t('Reset password') }}
    </a>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ProfilePassword',
  computed: {
    ...mapState({
      config: state => state.config,
    }),
    resetPasswordUrl () {
      return this.config?.ipchain_id?.password_reset_link || ''
    },
  },
}
</script>
