<template>
  <v-container>
    <h1 class="mb-3">
      {{ $t('Profile') }}
    </h1>

    <v-row>
      <v-col order="1" order-md="2" sm="6" md="3" cols>
        <v-card class="pa-5 simple-nav" flat>
          <h3 class="mb-6">
            {{ $t('My settings') }}
          </h3>
          <p>
            <router-link :to="{name: 'profile'}">
              {{ $t('Basic information') }}
            </router-link>
          </p>
          <p>
            <router-link :to="{name: 'profile-password'}">
              {{ $t('Change password') }}
            </router-link>
          </p>
          <p v-if="showSubscriptions">
            <router-link :to="{name: 'profile-subscription'}">
              {{ $t('Manage subscription') }}
            </router-link>
          </p>
          <p v-if="showTeammates">
            <router-link :to="{name: 'profile-teammates'}">
              {{ $t('Manage teammates') }}
            </router-link>
          </p>
        </v-card>
      </v-col>

      <v-col order="2" order-md="1" md="9" cols>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Profile',
  computed: {
    ...mapState({
      profile: state => state.profile,
    }),
    showSubscriptions () {
      return !this.profile.accountType
    },
    showTeammates () {
      return this.profile.accountType === 'business' &&
        this.profile.role === 'manager'
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
