export default [
  {
    country: 'Afghanistan',
    iso2code: 'af',
    dialCode: '93',
    id: '93-Afghanistan'
  },
  {
    country: 'Albania',
    iso2code: 'al',
    dialCode: '355',
    id: '355-Albania'
  },
  {
    country: 'Algeria',
    iso2code: 'dz',
    dialCode: '213',
    id: '213-Algeria'
  },
  {
    country: 'American Samoa',
    iso2code: 'as',
    dialCode: '1',
    id: '1-American-Samoa'
  },
  {
    country: 'Andorra',
    iso2code: 'ad',
    dialCode: '376',
    id: '376-Andorra'
  },
  {
    country: 'Angola',
    iso2code: 'ao',
    dialCode: '244',
    id: '244-Angola'
  },
  {
    country: 'Anguilla',
    iso2code: 'ai',
    dialCode: '1',
    id: '1-Anguilla'
  },
  {
    country: 'Antigua and Barbuda',
    iso2code: 'ag',
    dialCode: '1',
    id: '1-Antigua-and-Barbuda'
  },
  {
    country: 'Argentina',
    iso2code: 'ar',
    dialCode: '54',
    id: '54-Argentina'
  },
  {
    country: 'Armenia',
    iso2code: 'am',
    dialCode: '374',
    id: '374-Armenia'
  },
  {
    country: 'Aruba',
    iso2code: 'aw',
    dialCode: '297',
    id: '297-Aruba'
  },
  {
    country: 'Ascension Island',
    iso2code: 'ac',
    dialCode: '247',
    id: '247-Ascension-Island'
  },
  {
    country: 'Australia',
    iso2code: 'au',
    dialCode: '61',
    id: '61-Australia'
  },
  {
    country: 'Austria',
    iso2code: 'at',
    dialCode: '43',
    id: '43-Austria'
  },
  {
    country: 'Azerbaijan',
    iso2code: 'az',
    dialCode: '994',
    id: '994-Azerbaijan'
  },
  {
    country: 'Bahamas',
    iso2code: 'bs',
    dialCode: '1',
    id: '1-Bahamas'
  },
  {
    country: 'Bahrain',
    iso2code: 'bh',
    dialCode: '973',
    id: '973-Bahrain'
  },
  {
    country: 'Bangladesh',
    iso2code: 'bd',
    dialCode: '880',
    id: '880-Bangladesh'
  },
  {
    country: 'Barbados',
    iso2code: 'bb',
    dialCode: '1',
    id: '1-Barbados'
  },
  {
    country: 'Belarus',
    iso2code: 'by',
    dialCode: '375',
    id: '375-Belarus'
  },
  {
    country: 'Belgium',
    iso2code: 'be',
    dialCode: '32',
    id: '32-Belgium'
  },
  {
    country: 'Belize',
    iso2code: 'bz',
    dialCode: '501',
    id: '501-Belize'
  },
  {
    country: 'Benin',
    iso2code: 'bj',
    dialCode: '229',
    id: '229-Benin'
  },
  {
    country: 'Bermuda',
    iso2code: 'bm',
    dialCode: '1',
    id: '1-Bermuda'
  },
  {
    country: 'Bhutan',
    iso2code: 'bt',
    dialCode: '975',
    id: '975-Bhutan'
  },
  {
    country: 'Bolivia',
    iso2code: 'bo',
    dialCode: '591',
    id: '591-Bolivia'
  },
  {
    country: 'Bosnia and Herzegovina',
    iso2code: 'ba',
    dialCode: '387',
    id: '387-Bosnia-and-Herzegovina'
  },
  {
    country: 'Botswana',
    iso2code: 'bw',
    dialCode: '267',
    id: '267-Botswana'
  },
  {
    country: 'Brazil',
    iso2code: 'br',
    dialCode: '55',
    id: '55-Brazil'
  },
  {
    country: 'British Indian Ocean Territory',
    iso2code: 'io',
    dialCode: '246',
    id: '246-British-Indian-Ocean-Territory'
  },
  {
    country: 'British Virgin Islands',
    iso2code: 'vg',
    dialCode: '1',
    id: '1-British-Virgin-Islands'
  },
  {
    country: 'Brunei',
    iso2code: 'bn',
    dialCode: '673',
    id: '673-Brunei'
  },
  {
    country: 'Bulgaria',
    iso2code: 'bg',
    dialCode: '359',
    id: '359-Bulgaria'
  },
  {
    country: 'Burkina Faso',
    iso2code: 'bf',
    dialCode: '226',
    id: '226-Burkina-Faso'
  },
  {
    country: 'Burundi',
    iso2code: 'bi',
    dialCode: '257',
    id: '257-Burundi'
  },
  {
    country: 'Cambodia',
    iso2code: 'kh',
    dialCode: '855',
    id: '855-Cambodia'
  },
  {
    country: 'Cameroon',
    iso2code: 'cm',
    dialCode: '237',
    id: '237-Cameroon'
  },
  {country: 'Canada', iso2code: 'ca', dialCode: '1', id: '1-Canada'},
  {
    country: 'Cape Verde',
    iso2code: 'cv',
    dialCode: '238',
    id: '238-Cape-Verde'
  },
  {
    country: 'Caribbean Netherlands',
    iso2code: 'bq',
    dialCode: '599',
    id: '599-Caribbean-Netherlands'
  },
  {
    country: 'Cayman Islands',
    iso2code: 'ky',
    dialCode: '1',
    id: '1-Cayman-Islands'
  },
  {
    country: 'Central African Republic',
    iso2code: 'cf',
    dialCode: '236',
    id: '236-Central-African-Republic'
  },
  {
    country: 'Chad',
    iso2code: 'td',
    dialCode: '235',
    id: '235-Chad'
  },
  {
    country: 'Chile',
    iso2code: 'cl',
    dialCode: '56',
    id: '56-Chile'
  },
  {
    country: 'China',
    iso2code: 'cn',
    dialCode: '86',
    id: '86-China'
  },
  {
    country: 'Christmas Island',
    iso2code: 'cx',
    dialCode: '61',
    id: '61-Christmas-Island'
  },
  {
    country: 'Cocos Islands',
    iso2code: 'cc',
    dialCode: '61',
    id: '61-Cocos-Islands'
  },
  {
    country: 'Colombia',
    iso2code: 'co',
    dialCode: '57',
    id: '57-Colombia'
  },
  {
    country: 'Comoros',
    iso2code: 'km',
    dialCode: '269',
    id: '269-Comoros'
  },
  {
    country: 'Congo',
    iso2code: 'cd',
    dialCode: '243',
    id: '243-Congo'
  },
  {
    country: 'Congo',
    iso2code: 'cg',
    dialCode: '242',
    id: '242-Congo'
  },
  {
    country: 'Cook Islands',
    iso2code: 'ck',
    dialCode: '682',
    id: '682-Cook-Islands'
  },
  {
    country: 'Costa Rica',
    iso2code: 'cr',
    dialCode: '506',
    id: '506-Costa-Rica'
  },
  {
    country: 'Côte d’Ivoire',
    iso2code: 'ci',
    dialCode: '225',
    id: '225-C-te-d-Ivoire'
  },
  {
    country: 'Croatia',
    iso2code: 'hr',
    dialCode: '385',
    id: '385-Croatia'
  },
  {
    country: 'Cuba',
    iso2code: 'cu',
    dialCode: '53',
    id: '53-Cuba'
  },
  {
    country: 'Curaçao',
    iso2code: 'cw',
    dialCode: '599',
    id: '599-Cura-ao'
  },
  {
    country: 'Cyprus',
    iso2code: 'cy',
    dialCode: '357',
    id: '357-Cyprus'
  },
  {
    country: 'Czech Republic',
    iso2code: 'cz',
    dialCode: '420',
    id: '420-Czech-Republic'
  },
  {
    country: 'Denmark',
    iso2code: 'dk',
    dialCode: '45',
    id: '45-Denmark'
  },
  {
    country: 'Djibouti',
    iso2code: 'dj',
    dialCode: '253',
    id: '253-Djibouti'
  },
  {
    country: 'Dominica',
    iso2code: 'dm',
    dialCode: '1',
    id: '1-Dominica'
  },
  {
    country: 'Dominican Republic',
    iso2code: 'do',
    dialCode: '1',
    id: '1-Dominican-Republic'
  },
  {
    country: 'Ecuador',
    iso2code: 'ec',
    dialCode: '593',
    id: '593-Ecuador'
  },
  {
    country: 'Egypt',
    iso2code: 'eg',
    dialCode: '20',
    id: '20-Egypt'
  },
  {
    country: 'El Salvador',
    iso2code: 'sv',
    dialCode: '503',
    id: '503-El-Salvador'
  },
  {
    country: 'Equatorial Guinea',
    iso2code: 'gq',
    dialCode: '240',
    id: '240-Equatorial-Guinea'
  },
  {
    country: 'Eritrea',
    iso2code: 'er',
    dialCode: '291',
    id: '291-Eritrea'
  },
  {
    country: 'Estonia',
    iso2code: 'ee',
    dialCode: '372',
    id: '372-Estonia'
  },
  {
    country: 'Eswatini',
    iso2code: 'sz',
    dialCode: '268',
    id: '268-Eswatini'
  },
  {
    country: 'Ethiopia',
    iso2code: 'et',
    dialCode: '251',
    id: '251-Ethiopia'
  },
  {
    country: 'Falkland Islands',
    iso2code: 'fk',
    dialCode: '500',
    id: '500-Falkland-Islands'
  },
  {
    country: 'Faroe Islands',
    iso2code: 'fo',
    dialCode: '298',
    id: '298-Faroe-Islands'
  },
  {
    country: 'Fiji',
    iso2code: 'fj',
    dialCode: '679',
    id: '679-Fiji'
  },
  {
    country: 'Finland',
    iso2code: 'fi',
    dialCode: '358',
    id: '358-Finland'
  },
  {
    country: 'France',
    iso2code: 'fr',
    dialCode: '33',
    id: '33-France'
  },
  {
    country: 'French Guiana',
    iso2code: 'gf',
    dialCode: '594',
    id: '594-French-Guiana'
  },
  {
    country: 'French Polynesia',
    iso2code: 'pf',
    dialCode: '689',
    id: '689-French-Polynesia'
  },
  {
    country: 'Gabon',
    iso2code: 'ga',
    dialCode: '241',
    id: '241-Gabon'
  },
  {
    country: 'Gambia',
    iso2code: 'gm',
    dialCode: '220',
    id: '220-Gambia'
  },
  {
    country: 'Georgia',
    iso2code: 'ge',
    dialCode: '995',
    id: '995-Georgia'
  },
  {
    country: 'Germany',
    iso2code: 'de',
    dialCode: '49',
    id: '49-Germany'
  },
  {
    country: 'Ghana',
    iso2code: 'gh',
    dialCode: '233',
    id: '233-Ghana'
  },
  {
    country: 'Gibraltar',
    iso2code: 'gi',
    dialCode: '350',
    id: '350-Gibraltar'
  },
  {
    country: 'Greece',
    iso2code: 'gr',
    dialCode: '30',
    id: '30-Greece'
  },
  {
    country: 'Greenland',
    iso2code: 'gl',
    dialCode: '299',
    id: '299-Greenland'
  },
  {
    country: 'Grenada',
    iso2code: 'gd',
    dialCode: '1',
    id: '1-Grenada'
  },
  {
    country: 'Guadeloupe',
    iso2code: 'gp',
    dialCode: '590',
    id: '590-Guadeloupe'
  },
  {
    country: 'Guam',
    iso2code: 'gu',
    dialCode: '1',
    id: '1-Guam'
  },
  {
    country: 'Guatemala',
    iso2code: 'gt',
    dialCode: '502',
    id: '502-Guatemala'
  },
  {
    country: 'Guernsey',
    iso2code: 'gg',
    dialCode: '44',
    id: '44-Guernsey'
  },
  {
    country: 'Guinea',
    iso2code: 'gn',
    dialCode: '224',
    id: '224-Guinea'
  },
  {
    country: 'Guinea-Bissau',
    iso2code: 'gw',
    dialCode: '245',
    id: '245-Guinea-Bissau'
  },
  {
    country: 'Guyana',
    iso2code: 'gy',
    dialCode: '592',
    id: '592-Guyana'
  },
  {
    country: 'Haiti',
    iso2code: 'ht',
    dialCode: '509',
    id: '509-Haiti'
  },
  {
    country: 'Honduras',
    iso2code: 'hn',
    dialCode: '504',
    id: '504-Honduras'
  },
  {
    country: 'Hong Kong',
    iso2code: 'hk',
    dialCode: '852',
    id: '852-Hong-Kong'
  },
  {
    country: 'Hungary',
    iso2code: 'hu',
    dialCode: '36',
    id: '36-Hungary'
  },
  {
    country: 'Iceland',
    iso2code: 'is',
    dialCode: '354',
    id: '354-Iceland'
  },
  {country: 'India',
    iso2code: 'in',
    dialCode: '91',
    id: '91-India'
  },
  {
    country: 'Indonesia',
    iso2code: 'id',
    dialCode: '62',
    id: '62-Indonesia'
  },
  {country: 'Iran',
    iso2code: 'ir',
    dialCode: '98',
    id: '98-Iran'
  },
  {country: 'Iraq',
    iso2code: 'iq',
    dialCode: '964',
    id: '964-Iraq'
  },
  {
    country: 'Ireland',
    iso2code: 'ie',
    dialCode: '353',
    id: '353-Ireland'
  },
  {
    country: 'Isle of Man',
    iso2code: 'im',
    dialCode: '44',
    id: '44-Isle-of-Man'
  },
  {
    country: 'Israel',
    iso2code: 'il',
    dialCode: '972',
    id: '972-Israel'
  },
  {country: 'Italy',
    iso2code: 'it',
    dialCode: '39',
    id: '39-Italy'
  },
  {
    country: 'Jamaica',
    iso2code: 'jm',
    dialCode: '1',
    id: '1-Jamaica'
  },
  {country: 'Japan',
    iso2code: 'jp',
    dialCode: '81',
    id: '81-Japan'
  },
  {
    country: 'Jersey',
    iso2code: 'je',
    dialCode: '44',
    id: '44-Jersey'
  },
  {
    country: 'Jordan',
    iso2code: 'jo',
    dialCode: '962',
    id: '962-Jordan'
  },
  {
    country: 'Kazakhstan',
    iso2code: 'kz',
    dialCode: '7',
    id: '7-Kazakhstan'
  },
  {
    country: 'Kenya',
    iso2code: 'ke',
    dialCode: '254',
    id: '254-Kenya'
  },
  {
    country: 'Kiribati',
    iso2code: 'ki',
    dialCode: '686',
    id: '686-Kiribati'
  },
  {
    country: 'Kosovo',
    iso2code: 'xk',
    dialCode: '383',
    id: '383-Kosovo'
  },
  {
    country: 'Kuwait',
    iso2code: 'kw',
    dialCode: '965',
    id: '965-Kuwait'
  },
  {
    country: 'Kyrgyzstan',
    iso2code: 'kg',
    dialCode: '996',
    id: '996-Kyrgyzstan'
  },
  {
    country: 'Laos',
    iso2code: 'la',
    dialCode: '856',
    id: '856-Laos'
  },
  {
    country: 'Latvia',
    iso2code: 'lv',
    dialCode: '371',
    id: '371-Latvia'
  },
  {
    country: 'Lebanon',
    iso2code: 'lb',
    dialCode: '961',
    id: '961-Lebanon'
  },
  {
    country: 'Lesotho',
    iso2code: 'ls',
    dialCode: '266',
    id: '266-Lesotho'
  },
  {
    country: 'Liberia',
    iso2code: 'lr',
    dialCode: '231',
    id: '231-Liberia'
  },
  {
    country: 'Libya',
    iso2code: 'ly',
    dialCode: '218',
    id: '218-Libya'
  },
  {
    country: 'Liechtenstein',
    iso2code: 'li',
    dialCode: '423',
    id: '423-Liechtenstein'
  },
  {
    country: 'Lithuania',
    iso2code: 'lt',
    dialCode: '370',
    id: '370-Lithuania'
  },
  {
    country: 'Luxembourg',
    iso2code: 'lu',
    dialCode: '352',
    id: '352-Luxembourg'
  },
  {
    country: 'Macau',
    iso2code: 'mo',
    dialCode: '853',
    id: '853-Macau'
  },
  {
    country: 'Macedonia',
    iso2code: 'mk',
    dialCode: '389',
    id: '389-Macedonia'
  },
  {
    country: 'Madagascar',
    iso2code: 'mg',
    dialCode: '261',
    id: '261-Madagascar'
  },
  {
    country: 'Malawi',
    iso2code: 'mw',
    dialCode: '265',
    id: '265-Malawi'
  },
  {
    country: 'Malaysia',
    iso2code: 'my',
    dialCode: '60',
    id: '60-Malaysia'
  },
  {
    country: 'Maldives',
    iso2code: 'mv',
    dialCode: '960',
    id: '960-Maldives'
  },
  {
    country: 'Mali',
    iso2code: 'ml',
    dialCode: '223',
    id: '223-Mali'
  },
  {
    country: 'Malta',
    iso2code: 'mt',
    dialCode: '356',
    id: '356-Malta'
  },
  {
    country: 'Marshall Islands',
    iso2code: 'mh',
    dialCode: '692',
    id: '692-Marshall-Islands'
  },
  {
    country: 'Martinique',
    iso2code: 'mq',
    dialCode: '596',
    id: '596-Martinique'
  },
  {
    country: 'Mauritania',
    iso2code: 'mr',
    dialCode: '222',
    id: '222-Mauritania'
  },
  {
    country: 'Mauritius',
    iso2code: 'mu',
    dialCode: '230',
    id: '230-Mauritius'
  },
  {
    country: 'Mayotte',
    iso2code: 'yt',
    dialCode: '262',
    id: '262-Mayotte'
  },
  {
    country: 'Mexico',
    iso2code: 'mx',
    dialCode: '52',
    id: '52-Mexico'
  },
  {
    country: 'Micronesia',
    iso2code: 'fm',
    dialCode: '691',
    id: '691-Micronesia'
  },
  {
    country: 'Moldova',
    iso2code: 'md',
    dialCode: '373',
    id: '373-Moldova'
  },
  {
    country: 'Monaco',
    iso2code: 'mc',
    dialCode: '377',
    id: '377-Monaco'
  },
  {
    country: 'Mongolia',
    iso2code: 'mn',
    dialCode: '976',
    id: '976-Mongolia'
  },
  {
    country: 'Montenegro',
    iso2code: 'me',
    dialCode: '382',
    id: '382-Montenegro'
  },
  {
    country: 'Montserrat',
    iso2code: 'ms',
    dialCode: '1',
    id: '1-Montserrat'
  },
  {
    country: 'Morocco',
    iso2code: 'ma',
    dialCode: '212',
    id: '212-Morocco'
  },
  {
    country: 'Mozambique',
    iso2code: 'mz',
    dialCode: '258',
    id: '258-Mozambique'
  },
  {
    country: 'Myanmar',
    iso2code: 'mm',
    dialCode: '95',
    id: '95-Myanmar'
  },
  {
    country: 'Namibia',
    iso2code: 'na',
    dialCode: '264',
    id: '264-Namibia'
  },
  {
    country: 'Nauru',
    iso2code: 'nr',
    dialCode: '674',
    id: '674-Nauru'
  },
  {
    country: 'Nepal',
    iso2code: 'np',
    dialCode: '977',
    id: '977-Nepal'
  },
  {
    country: 'Netherlands',
    iso2code: 'nl',
    dialCode: '31',
    id: '31-Netherlands'
  },
  {
    country: 'New Caledonia',
    iso2code: 'nc',
    dialCode: '687',
    id: '687-New-Caledonia'
  },
  {
    country: 'New Zealand',
    iso2code: 'nz',
    dialCode: '64',
    id: '64-New-Zealand'
  },
  {
    country: 'Nicaragua',
    iso2code: 'ni',
    dialCode: '505',
    id: '505-Nicaragua'
  },
  {
    country: 'Niger',
    iso2code: 'ne',
    dialCode: '227',
    id: '227-Niger'
  },
  {
    country: 'Nigeria',
    iso2code: 'ng',
    dialCode: '234',
    id: '234-Nigeria'
  },
  {
    country: 'Niue',
    iso2code: 'nu',
    dialCode: '683',
    id: '683-Niue'
  },
  {
    country: 'Norfolk Island',
    iso2code: 'nf',
    dialCode: '672',
    id: '672-Norfolk-Island'
  },
  {
    country: 'North Korea',
    iso2code: 'kp',
    dialCode: '850',
    id: '850-North-Korea'
  },
  {
    country: 'Northern Mariana Islands',
    iso2code: 'mp',
    dialCode: '1',
    id: '1-Northern-Mariana-Islands'
  },
  {
    country: 'Norway',
    iso2code: 'no',
    dialCode: '47',
    id: '47-Norway'
  },
  {
    country: 'Oman',
    iso2code: 'om',
    dialCode: '968',
    id: '968-Oman'
  },
  {
    country: 'Pakistan',
    iso2code: 'pk',
    dialCode: '92',
    id: '92-Pakistan'
  },
  {
    country: 'Palau',
    iso2code: 'pw',
    dialCode: '680',
    id: '680-Palau'
  },
  {
    country: 'Palestine',
    iso2code: 'ps',
    dialCode: '970',
    id: '970-Palestine'
  },
  {
    country: 'Panama',
    iso2code: 'pa',
    dialCode: '507',
    id: '507-Panama'
  },
  {
    country: 'Papua New Guinea',
    iso2code: 'pg',
    dialCode: '675',
    id: '675-Papua-New-Guinea'
  },
  {
    country: 'Paraguay',
    iso2code: 'py',
    dialCode: '595',
    id: '595-Paraguay'
  },
  {
    country: 'Peru',
    iso2code: 'pe',
    dialCode: '51',
    id: '51-Peru'
  },
  {
    country: 'Philippines',
    iso2code: 'ph',
    dialCode: '63',
    id: '63-Philippines'
  },
  {
    country: 'Poland',
    iso2code: 'pl',
    dialCode: '48',
    id: '48-Poland'
  },
  {
    country: 'Portugal',
    iso2code: 'pt',
    dialCode: '351',
    id: '351-Portugal'
  },
  {
    country: 'Puerto Rico',
    iso2code: 'pr',
    dialCode: '1',
    id: '1-Puerto-Rico'
  },
  {
    country: 'Qatar',
    iso2code: 'qa',
    dialCode: '974',
    id: '974-Qatar'
  },
  {
    country: 'Réunion',
    iso2code: 're',
    dialCode: '262',
    id: '262-R-union'
  },
  {
    country: 'Romania',
    iso2code: 'ro',
    dialCode: '40',
    id: '40-Romania'
  },
  {
    country: 'Russia',
    iso2code: 'ru',
    dialCode: '7',
    id: '7-Russia'
  },
  {
    country: 'Rwanda',
    iso2code: 'rw',
    dialCode: '250',
    id: '250-Rwanda'
  },
  {
    country: 'Saint Barthélemy',
    iso2code: 'bl',
    dialCode: '590',
    id: '590-Saint-Barth-lemy'
  },
  {
    country: 'Saint Helena',
    iso2code: 'sh',
    dialCode: '290',
    id: '290-Saint-Helena'
  },
  {
    country: 'Saint Kitts and Nevis',
    iso2code: 'kn',
    dialCode: '1',
    id: '1-Saint-Kitts-and-Nevis'
  },
  {
    country: 'Saint Lucia',
    iso2code: 'lc',
    dialCode: '1',
    id: '1-Saint-Lucia'
  },
  {
    country: 'Saint Martin',
    iso2code: 'mf',
    dialCode: '590',
    id: '590-Saint-Martin'
  },
  {
    country: 'Saint Pierre and Miquelon',
    iso2code: 'pm',
    dialCode: '508',
    id: '508-Saint-Pierre-and-Miquelon'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    iso2code: 'vc',
    dialCode: '1',
    id: '1-Saint-Vincent-and-the-Grenadines'
  },
  {
    country: 'Samoa',
    iso2code: 'ws',
    dialCode: '685',
    id: '685-Samoa'
  },
  {
    country: 'San Marino',
    iso2code: 'sm',
    dialCode: '378',
    id: '378-San-Marino'
  },
  {
    country: 'São Tomé and Príncipe',
    iso2code: 'st',
    dialCode: '239',
    id: '239-S-o-Tom-and-Pr-ncipe'
  },
  {
    country: 'Saudi Arabia',
    iso2code: 'sa',
    dialCode: '966',
    id: '966-Saudi-Arabia'
  },
  {
    country: 'Senegal',
    iso2code: 'sn',
    dialCode: '221',
    id: '221-Senegal'
  },
  {
    country: 'Serbia',
    iso2code: 'rs',
    dialCode: '381',
    id: '381-Serbia'
  },
  {
    country: 'Seychelles',
    iso2code: 'sc',
    dialCode: '248',
    id: '248-Seychelles'
  },
  {
    country: 'Sierra Leone',
    iso2code: 'sl',
    dialCode: '232',
    id: '232-Sierra-Leone'
  },
  {
    country: 'Singapore',
    iso2code: 'sg',
    dialCode: '65',
    id: '65-Singapore'
  },
  {
    country: 'Sint Maarten',
    iso2code: 'sx',
    dialCode: '1',
    id: '1-Sint-Maarten'
  },
  {
    country: 'Slovakia',
    iso2code: 'sk',
    dialCode: '421',
    id: '421-Slovakia'
  },
  {
    country: 'Slovenia',
    iso2code: 'si',
    dialCode: '386',
    id: '386-Slovenia'
  },
  {
    country: 'Solomon Islands',
    iso2code: 'sb',
    dialCode: '677',
    id: '677-Solomon-Islands'
  },
  {
    country: 'Somalia',
    iso2code: 'so',
    dialCode: '252',
    id: '252-Somalia'
  },
  {
    country: 'South Africa',
    iso2code: 'za',
    dialCode: '27',
    id: '27-South-Africa'
  },
  {
    country: 'South Korea',
    iso2code: 'kr',
    dialCode: '82',
    id: '82-South-Korea'
  },
  {
    country: 'South Sudan',
    iso2code: 'ss',
    dialCode: '211',
    id: '211-South-Sudan'
  },
  {
    country: 'Spain',
    iso2code: 'es',
    dialCode: '34',
    id: '34-Spain'
  },
  {
    country: 'Sri Lanka',
    iso2code: 'lk',
    dialCode: '94',
    id: '94-Sri-Lanka'
  },
  {
    country: 'Sudan',
    iso2code: 'sd',
    dialCode: '249',
    id: '249-Sudan'
  },
  {
    country: 'Suriname',
    iso2code: 'sr',
    dialCode: '597',
    id: '597-Suriname'
  },
  {
    country: 'Svalbard and Jan Mayen',
    iso2code: 'sj',
    dialCode: '47',
    id: '47-Svalbard-and-Jan-Mayen'
  },
  {
    country: 'Sweden',
    iso2code: 'se',
    dialCode: '46',
    id: '46-Sweden'
  },
  {
    country: 'Switzerland',
    iso2code: 'ch',
    dialCode: '41',
    id: '41-Switzerland'
  },
  {
    country: 'Syria',
    iso2code: 'sy',
    dialCode: '963',
    id: '963-Syria'
  },
  {
    country: 'Taiwan',
    iso2code: 'tw',
    dialCode: '886',
    id: '886-Taiwan'
  },
  {
    country: 'Tajikistan',
    iso2code: 'tj',
    dialCode: '992',
    id: '992-Tajikistan'
  },
  {
    country: 'Tanzania',
    iso2code: 'tz',
    dialCode: '255',
    id: '255-Tanzania'
  },
  {
    country: 'Thailand',
    iso2code: 'th',
    dialCode: '66',
    id: '66-Thailand'
  },
  {
    country: 'Timor-Leste',
    iso2code: 'tl',
    dialCode: '670',
    id: '670-Timor-Leste'
  },
  {
    country: 'Togo',
    iso2code: 'tg',
    dialCode: '228',
    id: '228-Togo'
  },
  {
    country: 'Tokelau',
    iso2code: 'tk',
    dialCode: '690',
    id: '690-Tokelau'
  },
  {
    country: 'Tonga',
    iso2code: 'to',
    dialCode: '676',
    id: '676-Tonga'
  },
  {
    country: 'Trinidad and Tobago',
    iso2code: 'tt',
    dialCode: '1',
    id: '1-Trinidad-and-Tobago'
  },
  {
    country: 'Tunisia',
    iso2code: 'tn',
    dialCode: '216',
    id: '216-Tunisia'
  },
  {
    country: 'Turkey',
    iso2code: 'tr',
    dialCode: '90',
    id: '90-Turkey'
  },
  {
    country: 'Turkmenistan',
    iso2code: 'tm',
    dialCode: '993',
    id: '993-Turkmenistan'
  },
  {
    country: 'Turks and Caicos Islands',
    iso2code: 'tc',
    dialCode: '1',
    id: '1-Turks-and-Caicos-Islands'
  },
  {
    country: 'Tuvalu',
    iso2code: 'tv',
    dialCode: '688',
    id: '688-Tuvalu'
  },
  {
    country: 'U.S. Virgin Islands',
    iso2code: 'vi',
    dialCode: '1',
    id: '1-U-S-Virgin-Islands'
  },
  {
    country: 'Uganda',
    iso2code: 'ug',
    dialCode: '256',
    id: '256-Uganda'
  },
  {
    country: 'Ukraine',
    iso2code: 'ua',
    dialCode: '380',
    id: '380-Ukraine'
  },
  {
    country: 'United Arab Emirates',
    iso2code: 'ae',
    dialCode: '971',
    id: '971-United-Arab-Emirates'
  },
  {
    country: 'United Kingdom',
    iso2code: 'gb',
    dialCode: '44',
    id: '44-United-Kingdom'
  },
  {
    country: 'United States',
    iso2code: 'us',
    dialCode: '1',
    id: '1-United-States'
  },
  {
    country: 'Uruguay',
    iso2code: 'uy',
    dialCode: '598',
    id: '598-Uruguay'
  },
  {
    country: 'Uzbekistan',
    iso2code: 'uz',
    dialCode: '998',
    id: '998-Uzbekistan'
  },
  {
    country: 'Vanuatu',
    iso2code: 'vu',
    dialCode: '678',
    id: '678-Vanuatu'
  },
  {
    country: 'Vatican City',
    iso2code: 'va',
    dialCode: '39',
    id: '39-Vatican-City'
  },
  {
    country: 'Venezuela',
    iso2code: 've',
    dialCode: '58',
    id: '58-Venezuela'
  },
  {
    country: 'Vietnam',
    iso2code: 'vn',
    dialCode: '84',
    id: '84-Vietnam'
  },
  {
    country: 'Wallis and Futuna',
    iso2code: 'wf',
    dialCode: '681',
    id: '681-Wallis-and-Futuna'
  },
  {
    country: 'Western Sahara',
    iso2code: 'eh',
    dialCode: '212',
    id: '212-Western-Sahara'
  },
  {
    country: 'Yemen',
    iso2code: 'ye',
    dialCode: '967',
    id: '967-Yemen'
  },
  {
    country: 'Zambia',
    iso2code: 'zm',
    dialCode: '260',
    id: '260-Zambia'
  },
  {
    country: 'Zimbabwe',
    iso2code: 'zw',
    dialCode: '263',
    id: '263-Zimbabwe'
  },
  {
    country: 'Åland Islands',
    iso2code: 'ax',
    dialCode: '358',
    id: '358--land-Islands'
  }
]
